import Helper from '@/services/helper';

export default {
    async getLandingContent() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/article/get-landing-content');
        
        return result;
    },
    async getArticleList(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/article/get-list', param);
        
        return result;
    },
    async getArticleDetail(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/article/get-detail', param);
        
        return result;
    },
    async getArticleRelated(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/v2/article/get-related', param);
        
        return result;
    }
}