<template>
    <section class="blogs">
        <div class="item" v-for="(item, index) in contents" :key="index">
            <router-link :to="landing + '/' + item.urlParam" :title="item.title">
                <div class="top">
                    <img :src="item.picture" :alt="item.pictureAlt" :title="item.pictureAlt" @error="helper.setNoImg($event, 'blog')" />

                    <div class="description">
                        <h2>{{ item.title }}</h2>
                        <p v-if="isShowAbstract">{{ item.abstract }}</p>
                    </div>
                </div>

                <div class="bottom">
                    <div class="date">
                        <font-awesome-icon :icon="['fas', 'calendar-alt']" class="icon" />
                        {{ item.date }}
                    </div>
                    <div>
                        {{ item.view }} views
                    </div>
                </div>
            </router-link>
        </div>

        <div class="no-data" v-if="contents.length === 0">
			<b-icon icon="box-seam"></b-icon>
            <span>ไม่พบบทความ</span>
		</div>
    </section>
</template>

<script>
import Helper from '@/services/helper';

export default {
    data() {
        return {
            helper: Helper
        };
    },
    props: {
        contents: {
            type: Array,
            requred: true
        },
		landing: {
            type: String,
            default: ''
        },
        isShowAbstract: {
            type: Boolean,
            default: true,
            requred: false
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.blogs {
    @include gridContentWrapper(0 -5px);

    @media only screen and (max-width: $screenMedium) {
        @include gridContentWrapper(0 -3px);
    }

    .item {
        width: 25%;
        padding: 0 5px 10px;

        @media only screen and (max-width: $screenMedium) {
            padding: 0 3px 10px;
        }

        @media only screen and (max-width: $screenSmall) {
            width: 50%;
        }

        a {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: #000;
            background: #fff;
            height: 100%;

            .top {
                margin-bottom: 10px;

                img {
                    display: block;
                    width: 100%;
                }

                .description {
                    padding: 10px;

                    h2 {
                        margin-bottom: 10px;
                        font-size: 13px;
                        font-weight: bold;
                        line-height: 18px;
                        color: #000;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    p {
                        font-size: 12px;
                        line-height: 17px;
                        color: #555;
                        margin-bottom: 0;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;
                padding: 0 10px 10px;

                @media only screen and (max-width: $screenLarge) {
                    font-size: 11px;
                }

                .date {
                    .icon {
                        color: #ffcc00;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .no-data {
        margin: auto;
        padding: 100px 20px;
    }
}
</style>